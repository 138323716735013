import { ClientFormStatus } from './ClientFormStatus';
import { ClientFormUserRoleValues } from './ClientFormUserRoles';

type ClientFormVersionHistory = {
  id: string;
  role: ClientFormUserRoleValues;
  status: ClientFormStatus;
  acceptedStatus: boolean;
  remark: string;
  activityKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityPlaceholders: Record<string, any>;
  createdUtc: string;
  majorVersion: number;
  minorVersion: number;
  versionState: VersionState;
  clonedClientFormId: string | null;
  performedAction: PerformedAction;
  userId: string;
};

export enum VersionState {
  Draft = 0,
  Master = 1,
  Superseded = 2,
}

export enum PerformedAction {
  ClientFormCreated = 0,
  ClientFormCreatedPublic = 1,
  ClientFormImported = 2,
  ClientFormSubmit = 3,
  ClientFormValidate = 4,
  ClientFormReviewStarted = 5,
  ClientFormAcknowledge = 6,
  ClientFormApprove = 7,
  ClientFormReject = 8,
  ClientFormCompleted = 9,
  ClientFormReopened = 10,
  ClientFormArchive = 11,
  ClientFormUnarchived = 12,
  DocumentReplaced = 13,
  DocumentUnarchived = 14,
  ClientFormEditAsset = 15, // legacy
  Unknown = -1,
}

export default ClientFormVersionHistory;
