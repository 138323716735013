import { FormType } from './../../models/FormTypes';
import { atom } from 'recoil';
import { ClientFormUser } from '../../models/ClientFormUser';
import { VersionState } from '../../models/ClientFormVersionHistory';

type ActivityCommentsStats = {
  clientFormId: string;
  unreadCommentsCount: number;
  sectionCommentsCounts: Record<string, number>;
  actionCommentsCounts: Record<string, number>;
};

const currentFormTypeAtom = atom<(typeof FormType)[keyof typeof FormType] | null>({
  key: 'currentFormType',
  default: null,
});

const currentFormVersionStateAtom = atom<VersionState>({
  key: 'currentFormVersionState',
  default: VersionState.Draft,
});

const currentActivityCommentsStats = atom<ActivityCommentsStats>({
  key: 'currentActivityCommentsStats',
  default: { clientFormId: '', unreadCommentsCount: 0, sectionCommentsCounts: {}, actionCommentsCounts: {} },
});

const currentFormUsersAtom = atom<ClientFormUser[]>({
  key: 'currentFormUsers',
  default: [],
});

const formSectionUsersAtom = atom<ClientFormUser[]>({
  key: 'formSectionUsers',
  default: [],
});

const formCompletedSectionsAtom = atom<Record<string, boolean>>({
  key: 'formCompletedSections',
  default: {},
});

const hidePrivateCommentsAtom = atom<boolean>({
  key: 'hidePrivateComments',
  default: false,
});

const viewDistributionLogsAtom = atom<boolean>({
  key: 'viewDistributionLogs',
  default: false,
});

export {
  currentFormUsersAtom,
  formSectionUsersAtom,
  formCompletedSectionsAtom,
  hidePrivateCommentsAtom,
  currentActivityCommentsStats,
  currentFormTypeAtom,
  currentFormVersionStateAtom,
  viewDistributionLogsAtom,
};
